import React from "react";
import { createStore } from 'redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Favicon from 'react-favicon';
import {isMobile} from 'react-device-detect';
import {ComposableMap, Geographies, Geography, Marker, Line } from "react-simple-maps";
import {config} from "./config.js";
import Routes from "./Routes.js" 
import Main_State from './Main_State.js'
import utils from "./utils";
import './App.css';

var store_main = createStore(Main_State.reducer_main)

export default class Main extends React.Component {

constructor(props) {
  super(props);
  this.state = {
    key : Date.now(),
    key_status_info : new Date(),
    key_awb_info : new Date(),
    key_awb_number : new Date(),
    key_flights : new Date(),
    awb_info : null,
    status_info : [],
    statusHTML: "",
    routesHTML : "",
    flightsHTML : "",
    flights : [],
    routes : [],
    notification : [],
    notification2 : [],
    showProgressBar : false,
    showProgressBar2 : false,
    displayStatusHistory : false,
    carriers : [],
    stations : []
  };
}

async componentDidMount() {

  document.title = "Pathfinder";
 
  //await auth()
  this.setState({carriers : await getCarriers()})
  
  let number = this.props.match.params.id;
 
  if(number){
    
    let prefix = number.split("-")[0];
    let serial = number.split("-")[1];

    if(!prefix  || !serial || isNaN(prefix) || isNaN(serial)) {
      store_main.dispatch({type : 1, noAWBFound : true})
      this.setState({type : 1, key : new Date() })
      return
    }else{

      if(prefix.length !== 3){
        this.setNotification("Error", "Your AWB prefix number must be 3 digits.");
        return
      }
      
      if(serial.length !== 8){
        this.setNotification("Error", "Your AWB serial number must be 8 digits.");
        return
      }

      this.searchAWB2(prefix, serial);  

    }

  }else{

    store_main.dispatch({type : 1, noAWBFound : true})
    this.setState({type : 1, key : new Date() })

  }

}

 getHoursDiff(hour_min, minute_min, hour_max, minute_max){
  
  let minute_diff = 0

  if(hour_min <= hour_max){

    if(minute_min <= minute_max){
    
      minute_diff = (hour_max - hour_min)*60 + (minute_max - minute_min)

    }else{
  
      minute_diff = (hour_max - hour_min)*60 - (minute_min - minute_max)
    }
    
  }else{

    hour_max = 24 + hour_max
    
    if(minute_min <= minute_max){

      minute_diff = (hour_max - hour_min)*60 + (minute_max - minute_min)

    } else{

      minute_diff = (hour_max - hour_min)*60 - (minute_min - minute_max)

    }
    
  }

  return minute_diff


}

async getAWB(params){


  this.HideNotification();

  store_main.dispatch({type : 1,
     prefix : params.prefix,
     serial : params.serial,
     noAWBFound : true,
     reset : true
  })

  this.setState({
    statusHTML : "",
    flightsHTML : "",
    routesHTML : "",
    notification : []
  })

  this.setState({key : new Date()})

  let response = await Routes.get("/AWB", params)

  if(response.error && response.noAWBFound){
    this.setNotification("Info", response.message);
    store_main.dispatch({type : 1, noAWBFound : true})
    this.setState({key : new Date()})
    return response
  }else if(response.error){
    this.setNotification("Error", response.message, "error");
    store_main.dispatch({type : 1, noAWBFound : true})
    this.setState({key : new Date()})
    return response
  }else{

    let {awb_info, status_info, flights, flight_info, routes} = response

    awb_info = awb_info ? awb_info : null
    status_info = status_info ? status_info : []
    flights = flights ? flights : []
    flight_info = flight_info ? flight_info : null
    routes = routes ? routes : []

    let stations = []
    
    if(flight_info){
      stations = await getStations()
      stations = stations ? stations : []
    }


    store_main.dispatch({
      type : 1,
      awbInfo : awb_info,
      flightInfo : flight_info,
      statusInfo : status_info,
      flights : flights,
      routes : routes,
      stations : stations,
      noAWBFound : false,
    })


    this.setState({awb_info : awb_info});
    this.setState({status_info : status_info})
    this.setState({flights : flights})
    this.setState({routes : routes})
    this.setState({flight_info : flight_info})

    const statusHTML = this.formatStatusInfo(status_info)
    this.setState({statusHTML : statusHTML})

    const flightsHTML = this.formatFlights(flights)
    this.setState({flightsHTML : flightsHTML})

    const routesHTML = this.buildPath(routes)
    this.setState({routesHTML : routesHTML})
    
    this.setState({key : new Date()})

    return response
  }

}

handleSerial(e){
  let serial = e.target.value
  store_main.dispatch({type : 1, serial : serial})
}

handlePrefix(e){
  
  let prefix = e.target.value 
  
  prefix = (prefix && prefix.toLowerCase() === "prefix") ? "" : prefix
  
  store_main.dispatch({type : 1, prefix : prefix})

  if (prefix && prefix.length === 3) {
    let next_input = document.getElementById("form_serial");
    if (next_input) next_input.focus();
  }
}

handleEmail(e){
  let email = e.target.value
  email = email ? email.toLowerCase() : ""
  store_main.dispatch({type : 1, email : email})
}

async searchAWB2(awb_prefix, awb_serial){

  this.HideNotification();

  if(!awb_prefix || !awb_serial)
    return 
  
  let params = {
    serial : awb_serial,
    prefix : awb_prefix,
    isMobile : isMobile
  }

  this.setState({showProgressBar : true})   
  await this.getAWB(params)
  this.setState({showProgressBar : false})   

}

searchAWB = async(e) => {

  let {serial, prefix} = store_main.getState()
  
  this.HideNotification();


  if(!serial){
    this.setNotification("Error", "Please provide an AWB serial number.");
    return
  }

  if(!prefix || prefix === "prefix"){
    this.setNotification("Error", "Please select or provide an AWB prefix number.");
    return
  }

  if(isNaN(prefix)){
  	this.setNotification("Error", "AWB prefix must be a number.");
  	return
  }

  if(isNaN(serial)){
  	this.setNotification("Error", "AWB serial must be a number.");
  	return
  }

  if(serial.length !== 8){
  	this.setNotification("Error", "Your AWB number must have 8 digits.");
  	return
  }else{

    let params = {
      serial : serial,
      prefix : prefix,
      isMobile : isMobile
    }

    this.setState({showProgressBar : true})   
    await this.getAWB(params)
    this.setState({showProgressBar : false})

  }

}

formatStatusInfo(status_info) {
  
  if(!status_info)
    return

  const statuSHTML = status_info.map((fsu) => {
    let time = !fsu.event_date ? "" :  utils.convertDate2(fsu.event_date , true)
    let time_utc = !fsu.event_date_utc ? "" :  utils.convertDate2(fsu.event_date_utc, true,);
    let detailString = "";

    if(fsu.status)
      detailString = statusToHTML(fsu);

    if(isMobile){
      time = <span> {time.split("|")[0]} <br/> {time.split("|")[1]} </span> ; 
      time_utc = <span> {time_utc.split("|")[0]} <br/> {time_utc.split("|")[1]}</span>; 
    }
            
    return (
      <tr>
        <td>{fsu.status} </td>
        <td>{fsu.airport}</td>          
        <td>{detailString}</td>
        <td>{time}*</td> 
        <td>{time_utc}</td> 
      </tr>
    )
  });

  return statuSHTML
        
}

formatFlights(flights) {

  if(!flights)
    return
  
  let i = 0

  const flightsHTML = flights.map((flight) =>{    
    
    i = i + 1;
    let date = "";
    let day_diff = "";

    if(flight.flight_date)
      date = utils.convertDate2(flight.flight_date, false);
           
    if(flight.arrival_date < flight.departure_date)
      day_diff = "(+1)"
     
    if(!isMobile){  
      return[
        <tr>
          <td>{flight.origin}</td>
          <td>{flight.dest}</td>
          <td>{flight.carrier}</td>
          <td>{flight.flight_number} </td>
          <td>{date}</td>
          <td>{!flight.departure_date ? "" :  utils.convertTime(flight.departure_date) }</td>
          <td>{!flight.arrival_date ? "" : utils.convertTime(flight.arrival_date)} {day_diff}</td>
          <td>{flight.aircraft_type} </td>
          <td>{flight.pieces} </td>
          <td>{flight.weight} kg</td>
        </tr>
      ]
    }else{

      return [
        <tr> <td> <strong> Booked Segment #{i}</strong> </td> <td> </td>  </tr>,
        <tr> <td> Origin - Destination </td> <td>{flight.origin} - {flight.dest} </td>  </tr>,
        <tr> <td> Flight - Aircraft Type </td> <td>{flight.carrier}{flight.flight_number}/{date}  - {flight.aircraft_type} </td>  </tr>,
        <tr> <td> Dep (LT) -  Arr (LT) </td> <td> {!flight.departure_date ? "" :  flight.departure_date.slice(0,2) +":"+ flight.departure_date.slice(2,4) }  - {!flight.arrival_date ? "" : flight.arrival_date.slice(0,2) +":"+ flight.arrival_date.slice(2,4)} {day_diff} </td>  </tr>,
        <tr> <td> Pieces - Weight </td> <td>{flight.pieces} pcs - {flight.weight} kg </td>  </tr>,
      ]
    }
    }
  );
  
  return flightsHTML

}

buildPath(routes){
 
  const routesHTML = routes.map((route) => {

    let {awb_info} = this.state
    var routeHTML = [];
    var routeHTML2 = [];
    const nameHTML =  <h3 style={{textAlign : "center"}}>{route.name}</h3>  ; 

    for (var x in route.status) {

      let time = <span> No info received <br/> </span>
      let pieces_ = ""
      let weight_ = ""

      let statusHTML = ""
      let props_class = "" 
      let icon = utils.ICONS[x]

      if(Object.keys(route.status).length === 3)
        props_class = "card columns three";
      else
        props_class = "card columns six";

      let props_color = "grey" ;

      if(route.status[x]){

        let {pieces , weight, event_date} = route.status[x]

        pieces = pieces ? parseInt(pieces) : pieces

        time =   utils.convertDate2(event_date, true); 
        time = <span>{time}*</span>
        pieces_ = `${pieces} ${pieces > 1 ? "pcs" : "pc"}`
        weight_ = ` ${weight} kg`;

        if(pieces < parseInt(awb_info.pieces) )
          props_color = "#dc6823";
        else
          props_color = "green";

      }
              
      statusHTML = ( 
        <span> 
          <div  class ="card status inner" style={{backgroundColor : props_color }} > 
            {icon} <br />{x.toUpperCase()}
          </div> 
          <br/>
          <span class="status inner" style={{marginBottom : "0.5rem"}}>{time}</span>
          <span class="status inner"><br/>{pieces_}{weight_}</span>
        </span>
      )
              

      routeHTML.push(
        <div class={props_class } style={{  color : "black", textAlign : "center", lineHeight : 1, fontStyle : "italic"}}> 
          {statusHTML} 
        </div> 
      )

      routeHTML.push(<div class=""> </div>)
    }

    routeHTML2.push(<div>{routeHTML}</div>)

    let props_class_2 = "card  secondary columns four";
    
    if(Object.keys(route.status).length === 3)
      	props_class_2 = "card secondary columns seven ";
    
    return <div class={props_class_2}>{nameHTML}{routeHTML2} </div>
  });
  
  var routesHTML2 = [];
  let section = [];
  let nextroute = [];
  let nextroute2 = [];

  for(let i = 0; i < routesHTML.length; i = i + 3){
    nextroute = <div class="columns four"> </div>
    nextroute2 = <div class="columns four"> </div>
    
    if(i + 1 < routesHTML.length)
      nextroute = routesHTML[i+1]

    if(i + 2 < routesHTML.length)
      nextroute2 = routesHTML[i+2]
      
    section = <section > {routesHTML[i]} {nextroute}  {nextroute2} </section>;
  
    routesHTML2.push(section)

  }  

  return routesHTML2
}

validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

sendAWB = async(e) =>{


 try {

  let {prefix, serial,email} = store_main.getState()

  this.HideNotification()
  
  if(!this.validateEmail(email)){
    this.setNotification("Error", "Please fill in a valid email address.", "error", "bottom");
    return
  }

  let notrobot = (document.getElementById("notrobot").checked);

  if(!notrobot){
    this.setNotification("Error", "Please check the \"I'm not robot\" checkbox to send email", "error", "bottom");
    return    
  }

  let params = {
    email : email,
    prefix : prefix,
    serial : serial,
    isMobile : isMobile
  }

  this.setState({showProgressBar2 : true})

  let response = await Routes.get("/sendAWB", params)

  if(response.error){
    this.setNotification("Info", response.message , "warning", "bottom")
  }else{
    this.setNotification("Info", "You successfully sent AWB info.", "success",  "bottom")
  }

  this.setState({showProgressBar2 : false})

   
 } catch (error) {
   console.log(error)
   this.setState({showProgressBar2 : false})
   return
 }

}

subscribe = async(e)=> {

  try {

      let {prefix, serial, email, statusInfo} = store_main.getState()

      this.HideNotification()


      if(!this.validateEmail(email)){
        this.setNotification("Error", "Please fill in a valid email address.", "error", "bottom");
        return
      }

      let notrobot = (document.getElementById("notrobot").checked);

      if(!notrobot){
        this.setNotification("Error", "Please check the \"I'm not robot\" checkbox to send email.", "error", "bottom");
        return    
      }

      let lastStatus = statusInfo && statusInfo[0] ? statusInfo[0].status : ""  ;
      let lastTimeStamp =  statusInfo && statusInfo[0] ? statusInfo[0].event_date_utc : ""  ;

      let params = {
        email : email,
        prefix : prefix,
        serial : serial,
        current_status : lastStatus,
        event_date_utc : lastTimeStamp.toString(),
        isMobile : isMobile
      }


      this.setState({showProgressBar2 : true})

      let response = await Routes.get("/subscribe", params)
    
      if(response.error){
        this.setNotification("Info", response.message , "warning", "bottom")
      }else{
        this.setNotification("Info", "You successfully subscribed to notifications for this AWB.", "success",  "bottom")
      }
    
      this.setState({showProgressBar2 : false})
      
    
  } catch (error) {
        console.log(error)
        this.setState({showProgressBar2 : false})
        return
  }
}

HideNotification = e => {
    
  this.setState({notification : [], notification2 : []})

  let ele = document.getElementById("notification");
  
  if(ele)
    ele.setAttribute("style", "display:none;");

  ele = document.getElementById("notification2");

  if(ele)
    ele.setAttribute("style", "display:none;")

}

setNotification(titre = "", msg = "", type = "warning", where = "top"){



   	 
  let html = [<p> </p>];
  let id = where === "top" ? "notification" : "notification2";
	let classValue = "";

  if(type === "warning")
    classValue = "alert notification"
  else if(type === "success")
    classValue = "alert success"
  else if(type === "error")
    classValue = "alert error"

  if(titre || msg){

    if(isMobile){
      html.push(
        [<br/>,<br/>]
      )
    }
    
    html.push(
      <div class={classValue}  id={id} >
        <span style={{cursor : "pointer"}} class="close" onClick={this.HideNotification}></span>
        <dl>
          <dt>{titre}</dt>
          <dd>{msg}</dd>
        </dl>
      </div> 
    )
    
   }

   if(!msg)
    html = []

  if(where === "top"){
    this.setState({notification : html}, function() { 
      document.getElementById("notification").setAttribute("style", "display:true;");
    });
  }else{
    this.setState({notification2 : html}, function() { 
      document.getElementById("notification2").setAttribute("style", "display:true;");
    });        
  }


}

displayStatusHistory = e =>{

  let {displayStatusHistory} = store_main.getState()
  store_main.dispatch({type : 1, displayStatusHistory : !displayStatusHistory})
  this.setState({key_status_info : new Date()})
		
}	

render() {

  

  let {awbInfo, flightInfo, statusInfo, displayStatusHistory, noAWBFound, email} = store_main.getState();
  let lastStatus = statusInfo && statusInfo[0] ? statusInfo[0] : null;
  let lastStatusHTML = "";
  awbInfo = awbInfo ? awbInfo : {}
  const {prefix, serial, origin, dest, pieces, volume, weight} = awbInfo
  
  let carriers = this.state.carriers
  carriers = carriers ? carriers : []
  
  carriers = carriers.map((c) =>{
    return( <option value = {c.prefix} selected = {c.prefix ===  store_main.getState().prefix }> {c.carrier} - {c.prefix}</option>)
  })

  if(lastStatus){

    lastStatusHTML  = statusToHTML(lastStatus,true,true);

    if(isMobile)
      lastStatusHTML = <span> {lastStatusHTML.split(" - ")[0] } <br /> {lastStatusHTML.split(" - ")[1]} </span>;//
      lastStatusHTML =  <span> <strong style={{color:"#003366"}}> Last status: </strong> {lastStatusHTML} </span>  ;//
  }


  return (

    <div style = {{padding : isMobile ? '0.5em' : ''}} key = {this.state.key}>
      
      <Favicon url={require("./img/favicon2.png")} />

      <section class="full-width"  style={{background : "white", height :"5.7rem"}}>
        <div style={{margin : "0.3em"}}>
          <a href={config.front}> <img src={config.logo}  alt="logo" /> </a>
        </div>
      </section>
    
      <section style={{ paddingTop : "0.5rem"}}>
        <div class="columns twelve" style = {{paddingLeft : isMobile ? '1.5em' : '', paddingRight : isMobile ? '1.5em' : ''}}>
          {this.state.notification}
        </div>
      </section>

      <section style={{ paddingTop : "0.5rem"}}>
        <div class="card secondary"  key={this.state.key_awb_number}>
          <div class={isMobile ? "" : "row side-elements"} >
            <label  style={{ color : config.majorColor}} > Enter your AWB number </label>
            <center>
              <ProgressBar show={this.state.showProgressBar} />
            </center>
              
            <div class="columns two">
              <select 
                id="form_prefix" 
                style={{ fontSize : "1.2rem"}}
                onChange={(e) => {
                  this.handlePrefix(e)
                  this.setState({key_awb_number : new Date()})
                }} 

              >
                <option value="prefix" >Prefix</option>
                {carriers ? carriers : null}
              </select>
            </div>

            <div class="columns two">
                <input 
                  type="text" 
                  id="form_prefix1"
                  onChange={this.handlePrefix} 
                  defaultValue={store_main.getState().prefix}
                  maxLength = '3' 
                  placeholder="Prefix" style={{ fontSize : "1.2rem"}}/>

            </div>

            <div class="columns three">
                <input 
                  type="text" 
                  id="form_serial"
                  onChange={this.handleSerial} 
                  defaultValue={store_main.getState().serial}
                  maxLength = '8' 
                  placeholder="Serial" style={{ fontSize : "1.2rem"}}/>

            </div>

            <div class="columns one">
              <input type="submit" style={{backgroundColor:"#002847"}}   onClick={this.searchAWB}  value ="Search" />
            </div>

            <div class ="columns five">
              <p>*Local Date | Time </p>
            </div>

          </div>
        </div>
      </section>  
      
      {!noAWBFound &&
        <>
          <section style={{ padding : "0"}}>
            <div class="card secondary" id="table_info" key={this.state.key_awb_info}>
              <div class="columns eleven" style={{margin : 0, padding : 0}}   >
                <section>

                  <div class="columns two">
                    <h3 style={{ color : config.majorColor,margin : 0, padding : 0 }}> Shipment Info</h3>
                  </div>

                  <div class="columns ten">

                    <table className="" >
                      <thead>
                        <tr>
                          <th>AWB number</th>
                          <th>{isMobile ? "Orig" : "Origin"}</th>
                          <th>{isMobile ? "Dest" : "Destination"}</th>
                          <th>{isMobile ? "Pcs" : "Pieces"}</th>
                          <th>{isMobile ? "Wgt" : "Weight"}</th>
                          <th>{isMobile ? "Vol" : "Volume"}</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>{prefix} - {serial}</td>
                          <td>{origin}</td>
                          <td>{dest}</td>
                          <td>{pieces} </td>            
                          <td>{weight} kg</td>
                          <td>{parseFloat(volume).toFixed(1)} m³</td>
                        </tr>
                      </tbody>

                    </table>

                    { lastStatusHTML }

                  </div>
          
                </section>
              </div>
            </div>
          </section>
      
          <div id="table_routes">
            {this.state.routesHTML}
          </div>
        
          <section style={{ padding : 0}}> 
                
            <div class="card secondary" id="table_flights" key={this.state.key_flights}>
              <h3> Flight details</h3>
                <table className="" >
                    
                  <thead>
                    {!isMobile &&
                    <tr>
                      <th>Origin</th>
                      <th>Dest</th>
                      <th>Carrier</th>
                      <th> Flight number </th>
                      <th>Flight date</th>
                      <th>Dep (LT)</th>
                      <th>Arr (LT)</th>
                      <th>Aircraft Type</th>
                      <th>Pieces</th>
                      <th>Weight</th>
                    </tr>
                    }

                    {isMobile &&
                    <tr>
                      <th> </th>
                      <th> </th>
                    </tr>

                    }
                  
                  </thead>

                  <tbody>
                    {this.state.flightsHTML}
                  </tbody>

                </table>
            </div>

          </section> 
        
          <section>

            <div class="card secondary" id="table_status" key={this.state.key_status_info}>

              <div>
                <button onClick={this.displayStatusHistory}  id = "btn_history"  >
                  {displayStatusHistory ? "[-] Less details" : "[+] More details"}
                </button> 	 
              </div>

              {displayStatusHistory &&            
              <div id="table_status" ><br />
                
                <h3> Status history </h3>

                <table className="" >
                  <thead>
                    <tr>
                      <th>Status </th>
                      <th>Airport</th>
                      <th>Details</th>
                      <th>Local Time</th>
                      <th>UTC Time</th>
                    </tr>
                  </thead>

                  <tbody>
                    {this.state.statusHTML}
                  </tbody>
                </table>

              </div>
              }

            </div>

          </section>
          
          <section>
            <div class="columns twelve" style = {{paddingLeft : isMobile ? '1.5em' : '', paddingRight : isMobile ? '1.5em' : ''}}>
              {this.state.notification2}
            </div>
          </section>

          <section>
              <div class="card secondary" id="table_subscribe">
                <div class={isMobile ? "" : "row side-elements"} >
                  <div class="columns three">
                      <input 
                        type="text" 
                        id="form_mail"   
                        placeholder="Enter your email address"
                        onChange={this.handleEmail} 
                        defaultValue={email} 
                        style={{ fontSize : "1.2rem"}}
                      />
                  </div>

                  <div style={{margin :"1em"}}> 
                    <input type="checkbox" id="notrobot" name="notrobot" />
                    <label for="notrobot">I'm not a robot</label>
                  </div>
                            
                  <div class=""> 
                    <input type="submit"  onClick={this.sendAWB}  value ="Send this AWB info" /> 
                  </div>

                  <div style = {{padding : '0.5', marginRight : '0.5em', marginLeft : '0.5em'}}>
                    <center>
                      {isMobile ? <br/> : null}
                      <ProgressBar show={this.state.showProgressBar2} />
                    </center>
                  </div>

                  <div class=""> 
                    <input type="submit"  onClick={this.subscribe}  value ="Subscribe to notifications for this AWB" />  
                  </div>
                
                </div>

              </div>

          </section>
          
          <section  id="flight_map"   > 
            <FlightMap show={awbInfo && flightInfo} /> 
          </section>

        </>
      }
      

    </div>
  )
}

}

const MapChart = (props) => {
 
  const geoUrl = "/map.json";

  let {flightInfo, awbInfo, flights, stations} = store_main.getState()
  
  flightInfo = flightInfo ? flightInfo : {}
  stations = stations ? stations : []
  flights  = flights ? flights : []
  
  let {origin, destination, positions, last_position, waypoints, actual_in, actual_on} = flightInfo
  let origin_iata = origin ? origin.code_iata : null
  let dest_iata = destination ? destination.code_iata : null
  waypoints = waypoints ? waypoints : []

  if(!flightInfo || !awbInfo || !origin_iata || !dest_iata || !last_position )
    return <></>

  let origin_ = origin ? stations.filter((s) => s.station_code === origin_iata)[0] : null
  let destination_ =  destination ? stations.filter((s) => s.station_code === dest_iata)[0] : null
  
  if(!origin_ || !destination_)
    return <></>


  //POSITIONING MARKERS
  var markers = [];

  //ORIGIN and DEST MARKERS
  markers.push({name: origin_.station_code, coordinates: [origin_.long , origin_.lat ] ,  time: "", status : "done"} )
  markers.push({name: destination_.station_code, coordinates: [destination_.long, destination_.lat],   time: "", status : "todo" } )

  let center = { long :  (origin_.long + destination_.long)/2, lat : (origin_.lat + destination_.lat)/2 }

  //OTHER MILESTONES
  let x;


  flights.forEach((f)=>{
    
    //origin points
    if(f.origin !== origin_.station_code && f.origin !== destination_.station_code){
        x = stations.filter((s)=>s.station_code === f.origin)[0];
        markers.push({ name: f.origin, coordinates: [x.long, x.lat],   status : "other" } )
    }

    //dest points
    if(f.dest !== origin_.station_code && f.dest !== destination_.station_code   ){
      
      x = stations.filter((s)=>s.station_code === f.dest)[0];
      
      if(f.dest === dest_iata)
        markers.push({ name: f.dest, coordinates: [x.long, x.lat],  status : "destination" } )
      else
        markers.push({ name: f.dest, coordinates: [x.long, x.lat],  status : "other" } )
    }
  
  })

  // ORIGIN-PLANE TRAJECTORY
  // THEORETICAL
  let lines = [];

  for(let i = 0; i < waypoints.length; i = i+2){
    
    let lat1= parseFloat(waypoints[i])
    let long1=parseFloat(waypoints[i+1]) 

    lines.push([long1 , lat1] )
    
  }  

  let lines2 = [];

  for(let i = 10; i < positions.length ; i++){

    let p = positions[i]

    if(p && i < positions.length)
      lines2.push([p.longitude, p.latitude])
  }

  // PLANE ICON ANGLE CALCULATION
  // ANGLE IS CALCULATED BETWEEN TWO CLOSEST POINTS FROM PLANE

  var thetaPlane = 0;
  var translatePlane = 0;
  var translateDestLabel_X = 0
  var translateDestLabel_Y = 0
  var translateOrigLabel_X = 0
  var translateOrigLabel_Y = 0

//PLANE ANGLE & TEXT ORIENTATION
// THIS IS BASED ON DIRECTION FROM LAST POINTS OF PLANE TRAJECTORY

  if(positions.length > 5){
  
      //LETS COLLECT COORDINATES OF LAST POINTS FROM TRAJECTORY
      let length = positions.length;

      let lat2 = positions[length-1].latitude
      let long2 = positions[length-1].longitude

      let lat1 = positions[length-5].latitude
      let long1 = positions[length-5].longitude

      //plane is repositioned at the end of trajectory. Sometimes there is a difference, this cancels it.
      //var longP = long2
      //var latP = lat2

      let deltaLong = Math.abs( Math.abs(long2)  - Math.abs(long1))
      let deltaLat = Math.abs( Math.abs(lat2)  - Math.abs(lat1))

      let alpha = (180/Math.PI) * Math.atan( deltaLat / deltaLong  ) // absolute angle between trajectory direction and horizontal axis

      //console.log("alpha", alpha, "deltaLong", deltaLong, "deltaLat", deltaLat)
      
      if( lat2 > lat1 && long2 > long1  ){
        //console.log("DIR. NORD EST ") 

        //translate text
        translateOrigLabel_X = 15
        translateOrigLabel_Y = -15

        translateDestLabel_X = -15
        translateDestLabel_Y = 15

        thetaPlane =  45 - alpha  // 45° orienting plane towards east
        
        if(alpha < 3)
          translatePlane = "0,-10"
        else
          translatePlane =  "-12,-7" 

      }

      if( lat2 < lat1 && long2 > long1 ){
        //console.log("DIR. SUD EST / ")

        //translate text
        translateDestLabel_X = -15
        translateDestLabel_Y = -15

        translateOrigLabel_X = 15
        translateOrigLabel_Y = 15

        thetaPlane =  45 + alpha  // 45° orienting plane towards east
        
        if(alpha < 3)
        translatePlane = "15,-2"
        else
        translatePlane = "15,0"

      }

      if(lat2 > lat1 && long2 < long1   ){

          translateDestLabel_X = -15
          translateDestLabel_Y = -15

          translateOrigLabel_X =  15
          translateOrigLabel_Y =  15

        thetaPlane =  -135 + alpha // 135° orienting plane towards east

        if(alpha < 5)
          translatePlane = "-5,15"
        else
          translatePlane =   "-9,11" 

      }

      if(lat2 < lat1 && long2 < long1  ){

        thetaPlane = -135 - alpha // 135 orienting plane towards east

        //translate text
        translateOrigLabel_X = -15
        translateOrigLabel_Y = 15

        translateDestLabel_X = 20
        translateDestLabel_Y = -20

        //adjusting plane position after rotation
        if(alpha < 3)
        translatePlane = "0,15"
        else
        translatePlane = "10,10"

      }
  
  }
 
  return (
    <ComposableMap
      projection="geoMercator"
      projectionConfig={{
        center : [center.long, center.lat],
        scale: 300,
        width : 50
      }}
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map(geo => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                style={{
                  default: {
                     fill: "#043d58",
                     stroke: "#668392",
                     strokeWidth: 0.75,
                     ocean : "#ff"
                  },
                  hover: {
                    fill: "#043d58",
                    stroke: "#668392",
                    strokeWidth: 0.75,
                 },  
                 active: {
                  fill: "#043d58",
                  stroke: "#668392",
                  strokeWidth: 0.75,
               }             
                }}
              />
            ))
        }
      </Geographies>  
 
      {markers.map(({ name, coordinates,status }) => {
        
        let color = "white";
        let size ="1";
        let strokeWidth = "0"
        let fontSize = "0.4em"

        let translate_X = 0
        let translate_Y = 0

        if(status === "done"){
          color = "#32a852";
          size ="5"
          strokeWidth = "2"
          fontSize = "1.2em"
          translate_X = translateOrigLabel_X
          translate_Y = translateOrigLabel_Y
        }

        if(status === "todo"){
          color = "orange";
          size = "5"
          strokeWidth = "2"
          fontSize = "1.2em"
          translate_X = translateDestLabel_X
          translate_Y = translateDestLabel_Y
        }
    
        if(status === "other"){
          color = "grey";
          size = "5"
          strokeWidth = "1"
          fontSize = "0.7em"
        }
         
        
      return(
        <Marker key={name} coordinates={coordinates} >
          <g
            fill= {color}
            stroke="#FF5533"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(0, 0)"
          >
            <circle cx="0" cy="0" r= {size} stroke="#000000" strokeWidth={strokeWidth}/>
   
          </g>
           
          <text
            textAnchor="middle"
            y={translate_X}
            x={translate_Y}
            fontSize = {fontSize}
            style={{ fontFamily: "system-ui",   color : "white" }}
          >
            {name} 
          </text>
          
        </Marker>
      ) }
      )}

      <Line
        coordinates={lines}
        stroke="#776865"
        strokeWidth={1}
        strokeDasharray={[5, 5]}      
      />

      <Line
        coordinates={lines2}
        stroke="red"
        strokeWidth={1} 
      />

      <Marker key="plane" 
        coordinates={[last_position.longitude, last_position.latitude]} 
      >

        {positions.length > 5 ?
          <g 
            color = "white"  
            stroke = "red" 
            strokeWidth = {2} 
            transform = {"translate("+translatePlane+"),scale(0.04,0.04),rotate("+thetaPlane.toString()+")"}
          >
                                
            {(!last_position.longitude && !last_position.latitude) || actual_in || actual_on ? <span></span> :  
            <svg> 
              <path d="M497.535,14.465c-19.569-19.568-51.395-19.241-70.557,0.726L322.092,124.488L66.131,39.781L12.4,93.513l213.352,131.365
              L117.796,337.372l-69.231-11.366L0,374.571l101.78,35.649L137.429,512l48.565-48.565l-11.366-69.231l112.494-107.955
              L418.487,499.6l53.732-53.732l-84.706-255.961L496.808,85.022C516.776,65.86,517.103,34.034,497.535,14.465z"/> 
            </svg>
            }
                    
          </g> : null 

        }

      </Marker>

    </ComposableMap>
  );
};

function ProgressBar(props){
    if(props.show)
      return <CircularProgress  style={{ color : config.majorColor}}/>
    else
      return null
    
}

async function getStations(){
  
  let response = await Routes.get("/stations", {})
  return response.error ? null : response.stations
  
}

async function getCarriers() {
  let response = await Routes.get("/carriers", {})
  return response.error ? null : response.carriers
}

async function auth() {
  /*
  let response = await Routes.post("/auth")
  console.log("freeAppToken",response)

  if(response.error){
    localStorage.setItem("appToken", "")
  }else{
    localStorage.setItem("appToken", response.token)
  }

  console.log("freeLocation",window.location)
  */
  
}

function statusToHTML(lastStatus, getTime=false, mobileTable=false ){

    let lastStatusHTML = "";

    let timestamp = !lastStatus.event_date ?  "" : utils.convertDate2(lastStatus.event_date.toString(), true);
    
    let flight_date = !lastStatus.flight_date ? "" : utils.convertDate2(lastStatus.flight_date, false) 

  let name = utils.statusNames[lastStatus.status];
 
  if(lastStatus.status === "DEP" || lastStatus.status === "RCF" || lastStatus.status === "MAN"){

    let part = " on flight";

    if(lastStatus.status === "RCF")
      part = "";

    lastStatusHTML = name + part + "  " + 
    lastStatus.origin + "-" + 
    lastStatus.dest + " " +
    lastStatus.carrier + 
    lastStatus.flight_number + "/" + 
    flight_date   ;

    lastStatusHTML = lastStatusHTML + " - "+ lastStatus.pieces + "pcs " + lastStatus.weight + "kg" ;

  }else{
    lastStatusHTML = name + " - " + lastStatus.pieces + "pcs " + lastStatus.weight  + "kg" 
  }

  if(getTime)
    lastStatusHTML = lastStatusHTML + " --- " + lastStatus.airport + ", " + timestamp + "*"

  return lastStatusHTML

}
/*
function FlightMap(props){
 
  if(!props.show)
    return <></>
  
  let {flightInfo, stations} = store_main.getState();

  if(!stations || !flightInfo)
    return <></>
  
  let {
    origin, 
    destination, 
    scheduled_out,
    scheduled_off,
    scheduled_on,
    scheduled_in,
    estimated_out,
    estimated_off,
    estimated_on,
    estimated_in,
    actual_out,
    actual_off,
    actual_on,
    actual_in,
    positions, 
    last_position, 
    waypoints,
  } = flightInfo

  console.log('freeFI', flightInfo)

  let origin_iata = origin ? origin.code_iata : null
  let dest_iata = destination ? destination.code_iata : null

  let origin_ = stations.filter((s) => s.station_code === origin_iata)[0];
  let destination_ = stations.filter((s) => s.station_code === dest_iata)[0];

  
  let departure_hour_utc = null;
  let departure_hour = null;
  let departure_minute = null;
  let departure_text = "";

  let off = null 
  
  if(actual_off){
    off = actual_off
    departure_text = "Actual"
  }else if(estimated_off){
    off = estimated_off
    departure_text = "Estimated"
  }else if(scheduled_off){
    off = scheduled_off
    departure_text = "Schedule"
  }


  console.log('freeFI1', getFlightTimes(flightInfo))

  return 



  if (new Date(flightInfo.adt).getFullYear()  !== 1970){
    
    let departure_date = new Date(flightInfo.adt)
    departure_minute = departure_date.getUTCMinutes();
    departure_hour_utc = departure_date.getUTCHours() 
    departure_hour = departure_hour_utc + (origin.utc_diff/60) ; //Local hour
    departure_text = "Actual "
    

    if(departure_hour > 23){
      departure_hour = departure_hour - 24
    }

    if(departure_hour < 0){
      departure_hour = 24 + departure_hour
    }

  }else{
    
    departure_minute =  parseInt(flightInfo.departure_date.substring(2,4))  ;
    departure_hour_utc =   parseInt(flightInfo.departure_date.substring(0,2)) - origin.utc_diff/60;
    departure_hour = parseInt(flightInfo.departure_date.substring(0,2))
    departure_text = "Scheduled " 
 
    if(departure_hour > 23){
      departure_hour = departure_hour - 24
    }

    if(departure_hour < 0){
      departure_hour = 24 + departure_hour
    }

  }

  //arrival time
  let arrival_hour_utc = null;
  let arrival_hour = null;
  let arrival_minute = null;
  let arrival_text = ""


  // we can use flight aware time if available, or scheduled time if not
  if (new Date(flightInfo.eat).getFullYear()  !== 1970){
    
   
    let arrival_date = new Date(flightInfo.eat)
    arrival_minute = arrival_date.getUTCMinutes();
    arrival_hour_utc = arrival_date.getUTCHours();
    arrival_hour = arrival_hour_utc + (dest.utc_diff/60);
    arrival_text = "Estimated ";
    
    if(arrival_hour > 23){
      arrival_hour = arrival_hour - 24
    }

    if(arrival_hour < 0){
      arrival_hour =  24 + arrival_hour 
    }
              
  }else{
  

    arrival_hour =   parseInt(flightInfo.arrival_date.substring(0,2))  ;
    arrival_hour_utc =  arrival_hour - dest.utc_diff/60;
    arrival_minute =   parseInt(flightInfo.arrival_date.substring(2,4))  ;
    arrival_text = "Scheduled " 
    
    if(arrival_hour > 23){
      arrival_hour = arrival_hour - 24
    }

    if(arrival_hour < 0){
      arrival_hour =  24 + arrival_hour 
    } 
   
  }
  
  let current_hour = new Date().getUTCHours();
  let current_minute = new Date().getUTCMinutes()

  let elapsed = current_minute - departure_minute >= 0 ? current_hour - departure_hour_utc : current_hour - departure_hour_utc - 1   ;
  let elapsed_minute = current_minute - departure_minute >= 0 ? current_minute - departure_minute : 60 + current_minute - departure_minute;
  
  let remaining = arrival_minute - current_minute >= 0 ? arrival_hour_utc - current_hour : arrival_hour_utc - current_hour - 1   ;
  let remaining_minute = arrival_minute - current_minute >= 0 ? arrival_minute - current_minute : 60 + arrival_minute - current_minute;
  remaining_minute = remaining < 0 ? 0 : remaining_minute;

  let totalFlight = arrival_minute - departure_minute >= 0 ? arrival_hour_utc - departure_hour_utc : arrival_hour_utc - departure_hour_utc - 1   ;
  let totalFlight_minute = arrival_minute - departure_minute >= 0 ? arrival_minute - departure_minute : 60 + arrival_minute - departure_minute;

  if(departure_hour_utc > arrival_hour_utc){
    totalFlight = arrival_minute - departure_minute >= 0 ? (arrival_hour_utc + 24) - departure_hour_utc : (arrival_hour_utc + 24) - departure_hour_utc - 1   ;
    totalFlight_minute = arrival_minute - departure_minute >= 0 ? arrival_minute - departure_minute : 60 + arrival_minute - departure_minute;
  }

    
    
    return(
  
      <div class="card secondary flight_map"  style={{ color : "white"}}> 
        <h3>Flight Map</h3> 
        
        <p style={{ color : "white"}}> 
          <Stepper  id = "stepper_flight_map" activeStep={1}  alternativeLabel>
            
            <Step key={1}>
              <StepLabel  StepIconComponent={StepTakeoffIcon}> 
                <p>

                  Departed from <strong>{flightInfo.origin}</strong>({origin.city_name})
                  
                  <br />

                  {departure_text} 

                  <strong>
                    { 
                      (departure_hour >= 10 ? departure_hour : "0" + departure_hour) + ":" + (departure_minute >= 10 ? departure_minute : "0" + departure_minute) 
                    } (LT) 
                  </strong>      
                  
                  <br /> 
                  
                  <strong> 
                    {elapsed > 0 ? (elapsed >= 10 ? elapsed : "0" + elapsed) + "h" : ""}  {elapsed_minute > 0 ? (elapsed_minute >= 10 ? elapsed_minute : "0" + elapsed_minute) +  "min" : ""} elapsed
                  </strong> 
                </p> 
              </StepLabel>
            </Step>

            <Step key={2}>
              <StepLabel StepIconComponent={StepMiddleIcon}>
                <p>
                  <strong>{flightInfo.flight_number}</strong> {flightInfo.aircraft_type}
                  <br/>  
                  <strong> 
                    {totalFlight > 0 ? (totalFlight >= 10 ? totalFlight : "0" + totalFlight) + "h" : ""} {totalFlight_minute > 0 ? (totalFlight_minute >= 10 ? totalFlight_minute : "0" + totalFlight_minute) +  "min" : ""} {"total flight time"}
                  </strong>        
                      
                  </p>
                  </StepLabel>
            </Step>

            <Step key={3}>
              <StepLabel StepIconComponent={StepLandingIcon}>
                <p>  
                  Arrival in <strong>{flightInfo.dest}</strong> ({dest.city_name })
                  
                  <br/>
                  
                  {arrival_text} 
                  
                  <strong> 
                    { 
                      (arrival_hour >= 10 ? arrival_hour : "0" + arrival_hour) + ":" + (arrival_minute >= 10 ? arrival_minute : "0" + arrival_minute) 
                    } (LT)  
                  </strong>
                        
                  <br/> 
                  
                  <strong> 
                    {remaining > 0 ? (remaining >= 10 ? remaining : "0" + remaining) + "h" : ""} {remaining_minute > 0 ? (remaining_minute >= 10 ? remaining_minute : "0" + remaining_minute) +  "min" : ""} {remaining < 0 ? "" : "remaining"} 
                  </strong> 
                        
                </p>
              </StepLabel>
            </Step>
          </Stepper>
        </p>

        <MapChart />
  
    </div>
   
    )
  
}
*/

function FlightMap(props){
 
  if(!props.show)
    return <></>
  
  let {flightInfo, stations} = store_main.getState();

  if(!stations || !flightInfo)
    return <></>
  
  let {
    flight_number,
    aircraft_type,
    origin, 
    destination,
    status,
    actual_on,
    actual_in,
    last_position
  } = flightInfo


  if(!origin || !destination )
  return <></>


  let origin_iata = origin ? origin.code_iata : null
  let dest_iata = destination ? destination.code_iata : null

  let origin_ = stations.filter((s) => s.station_code === origin_iata)[0];
  let destination_ = stations.filter((s) => s.station_code === dest_iata)[0];

  
  let departure_hour_utc = null;
  let departure_hour = null;
  let departure_minute = null;
  let arrival_hour_utc = null;
  let arrival_hour = null;
  let arrival_minute = null;

  let {take_off, take_on, departure_based_on, arrival_based_on, ago_hour, ago_minutes} = getFlightTimes(flightInfo)


  let status_text = status.toLowerCase()
  let status_style = {
    color : status && (status_text.includes("on time") || status_text.includes("arrived")  ) ? "green" : "red"
  }

  if(take_off){
    
    let departure_date = new Date(take_off)
    departure_minute = departure_date.getUTCMinutes();
    departure_hour_utc = departure_date.getUTCHours() 
    departure_hour = departure_hour_utc + (origin_.utc_diff/60) ; //Local hour
  
    if(departure_hour > 23){
      departure_hour = departure_hour - 24
    }

    if(departure_hour < 0){
      departure_hour = 24 + departure_hour
    }

  }

  if(take_on){
    
    let arrival_date = new Date(take_on)
    arrival_minute = arrival_date.getUTCMinutes();
    arrival_hour_utc = arrival_date.getUTCHours();
    arrival_hour = arrival_hour_utc + (destination_.utc_diff/60);
    
    if(arrival_hour > 23){
      arrival_hour = arrival_hour - 24
    }

    if(arrival_hour < 0){
      arrival_hour =  24 + arrival_hour 
    }
              
  }
  
  let current_hour = new Date().getUTCHours();
  let current_minute = new Date().getUTCMinutes()

  let elapsed = current_minute - departure_minute >= 0 ? current_hour - departure_hour_utc : current_hour - departure_hour_utc - 1   ;
  let elapsed_minute = current_minute - departure_minute >= 0 ? current_minute - departure_minute : 60 + current_minute - departure_minute;
  
  let remaining = arrival_minute - current_minute >= 0 ? arrival_hour_utc - current_hour : arrival_hour_utc - current_hour - 1   ;
  let remaining_minute = arrival_minute - current_minute >= 0 ? arrival_minute - current_minute : 60 + arrival_minute - current_minute;
  remaining_minute = remaining < 0 ? 0 : remaining_minute;

  let totalFlight = arrival_minute - departure_minute >= 0 ? arrival_hour_utc - departure_hour_utc : arrival_hour_utc - departure_hour_utc - 1   ;
  let totalFlight_minute = arrival_minute - departure_minute >= 0 ? arrival_minute - departure_minute : 60 + arrival_minute - departure_minute;

  if(departure_hour_utc > arrival_hour_utc){
    totalFlight = arrival_minute - departure_minute >= 0 ? (arrival_hour_utc + 24) - departure_hour_utc : (arrival_hour_utc + 24) - departure_hour_utc - 1   ;
    totalFlight_minute = arrival_minute - departure_minute >= 0 ? arrival_minute - departure_minute : 60 + arrival_minute - departure_minute;
  }
  
  arrival_hour = arrival_hour >= 10 ? arrival_hour : "0" + arrival_hour
  arrival_minute = arrival_minute >= 10 ? arrival_minute : "0" + arrival_minute
  departure_hour = departure_hour >= 10 ? departure_hour : "0" + departure_hour
  departure_minute = departure_minute  >= 10 ? departure_minute : "0" + departure_minute
  elapsed = elapsed > 0 ? (elapsed >= 10 ? elapsed : "0" + elapsed ) + "h" : ""
  elapsed_minute = elapsed_minute > 0 ? (elapsed_minute >= 10 ? elapsed_minute : "0" + elapsed_minute) + "min" : ""
  remaining = remaining > 0 ? (remaining >= 10 ? remaining : "0" + remaining) + "h" : ""
  remaining_minute = remaining_minute > 0 ? (remaining_minute >= 10 ? remaining_minute : "0" + remaining_minute ) +  "min" : ""
  totalFlight = totalFlight > 0 ? (totalFlight >= 10 ? totalFlight : "0" + totalFlight) + "h" : ""
  totalFlight_minute = totalFlight_minute > 0 ? (totalFlight_minute >= 10 ? totalFlight_minute : "0" + totalFlight_minute) +  "min" : ""
  ago_hour = ago_hour > 0 ? (ago_hour >= 10 ? ago_hour : "0" + ago_hour) + "h" : ""
  ago_minutes = ago_minutes > 0 ? (ago_minutes >= 10 ? ago_minutes : "0" + ago_minutes) + "min" : ""

    
    return(
  
      <div class="card secondary flight_map"  style={{ color : "white"}}> 
        <h3>Flight Map</h3> 
        
        <p style={{ color : "white"}}> 
          <Stepper  id = "stepper_flight_map" activeStep={1}  alternativeLabel>
            
            <Step key={1}>
              <StepLabel  StepIconComponent={StepTakeoffIcon}> 
                <p>

                  Departed from <strong>{origin.code_iata}</strong> ({origin.city})
        
                  <br />

                  {departure_based_on ? departure_based_on : ""} <strong>{departure_hour}:{departure_minute} (LT)</strong>      
                  
                  <br /> 
                  
                  <strong> 
                     {actual_in || actual_on || !last_position?  null : elapsed + " " + elapsed_minute + " elapsed"}
                  </strong> 
                </p> 
              </StepLabel>
            </Step>

            <Step key={2}>
              <StepLabel StepIconComponent={StepMiddleIcon}>
                <p>
                  <strong>{flight_number}</strong> {aircraft_type}
                  <br/>  
                  <strong> 
                    {totalFlight} {totalFlight_minute} total flight time
                  </strong>  

                  <br/>

                  <strong><p style={status_style}>{status} {ago_hour} {ago_minutes ? ago_minutes + " ago" : ""}</p></strong>      
                      
                  </p>
                  </StepLabel>
            </Step>

            <Step key={3}>
              <StepLabel StepIconComponent={StepLandingIcon}>
                <p>  
                  Arrival in <strong>{destination.code_iata}</strong> ({destination.city})
                  
                  <br/>
                  
                  {arrival_based_on ? arrival_based_on : ""} <strong>{arrival_hour}:{arrival_minute} (LT)</strong>      
    
                  <br/> 
                  
                  <strong> 
                    
                    {actual_in || actual_on || !last_position ?  null : remaining + " " + remaining_minute + " remaining"}
                    
                  </strong> 
                        
                </p>
              </StepLabel>
            </Step>
          </Stepper>
        </p>

        <MapChart />
  
    </div>
   
    )
  
}

function getFlightTimes(flightInfo){
 let { 
    scheduled_out,
    scheduled_off,
    scheduled_on,
    scheduled_in,
    estimated_out,
    estimated_off,
    estimated_on,
    estimated_in,
    actual_out,
    actual_off,
    actual_on,
    actual_in
  } = flightInfo


    let take_off = null 
    let take_on = null
    let ago_day = null
    let ago_hour = null
    let ago_minutes = null
    let departure_based_on = ""
    let arrival_based_on = ""
  
  if(actual_off){
    take_off = actual_off
    departure_based_on = "Actual"
  }else if(estimated_off){
    take_off = estimated_off
    departure_based_on = "Estimated"
  }else if(scheduled_off){
    take_off = scheduled_off
    departure_based_on = "Schedule"
  }

  if(actual_on){
    take_on = actual_on
    arrival_based_on = "Actual"
  }else if(estimated_on){
    take_on = estimated_on
    arrival_based_on = "Estimated"
  }else if(scheduled_on){
    take_on = scheduled_on
    arrival_based_on = "Schedule"
  }


  if(actual_in || actual_on){
    
    let now = new Date()
    let actual = actual_in || actual_on
    
    actual = new Date(actual)

    let ago_time = now.getTime() - actual.getTime()
    let ago_to_hour = ago_time/(1000*60*60)
    ago_hour = Math.floor(ago_to_hour)
    ago_minutes = parseInt((ago_to_hour - Math.floor(ago_to_hour))*60)
    //ago_day = ago_hour >= 24 ? Math.floor(ago_hour/24) : 0

  }


  return {
    take_off : take_off,
    take_on : take_on,
    departure_based_on : departure_based_on,
    arrival_based_on : arrival_based_on,
    ago_hour : ago_hour,
    ago_minutes : ago_minutes
  }

}

var StepMiddleIcon = (props) => <span>{utils.ICONS.plane}</span>

var StepLandingIcon= (props) => <span>{utils.ICONS.arrival}</span>
 
var StepTakeoffIcon= (props) => <span>{utils.ICONS.take_off}</span>




